<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-message
        </v-icon>
        <span
          v-if="(!contacts || !contacts.length)"
        >
          Compose mass message
        </span>
        <span
          v-if="!(!contacts || !contacts.length)"
        >
          Compose message
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          <span
            v-if="(!contacts || !contacts.length)"
          >
            Compose mass message
          </span>
          <span
            v-if="!(!contacts || !contacts.length)"
          >
            Compose message
          </span>
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.name"
                label="Name *"
                required
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.mediaUrl"
                label="Media URL"
              />
            </v-col>
          </v-row> -->
          <v-row v-if="templates && templates.length">
            <div class="placeHoldersWrapper">
              <div class="label">
                Select a template to use:
              </div>
              <div class="placeholderSelectorWrapper">
                <label class="dropdown">

                  <div class="dd-button">
                    Select
                  </div>

                  <input
                    id="test"
                    type="checkbox"
                    class="dd-input"
                  >

                  <ul class="dd-menu">
                    <li
                      v-for="template in templates"
                      :key="template.templateID"
                      @click="replaceBody(template)"
                    >
                      {{ template.name }}
                    </li>
                  </ul>

                </label>
              </div>
            </div>
          </v-row>
          <v-textarea
            v-model="field.message"
            label="Message *"
            required
            counter
            full-width
            single-line
          />
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="selectedLanguage"
                :items="languagesArr"
                item-text="name"
                item-value="value"
                label="Select a language for translation"
                @change="makeTranslation"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <input
                ref="file"
                type="file"
                style="display: none"
                @change="onFileChange"
              >
              <div
                v-if="!!file"
                class="fileWrapper"
              >
                <div class="filePart">
                  <div class="iconWrapper">
                    <v-icon>mdi-file</v-icon>
                  </div>
                  <div class="nameWrapper">
                    {{ file.name }}
                    <div class="fileSize">
                      Size: {{ formatMemory(file.size) }}
                    </div>
                  </div>
                </div>
                <div
                  class="actionPart"
                  @click="$refs.file.click()"
                >
                  <div class="changeBtn">
                    <v-icon>mdi-refresh</v-icon>
                    Change
                  </div>
                  <div
                    class="removeBtn"
                    @click="onFileDelete"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </div>
                </div>
              </div>
              <div
                v-if="!file"
                class="uploadBtn"
                @click="$refs.file.click()"
              >
                <div class="iconWrapper">
                  <v-icon>mdi-attachment</v-icon>
                </div>
                <div class="labelWrapper">
                  Attach an image
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="maxMessageLength < 200">
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="signature"
                label="Signature"
                required
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="toggleWrapper"
            >
              <input
                v-model="shouldSchedule"
                type="checkbox"
                class="toggle"
              >
              <span>Schedule</span>
            </v-col>
          </v-row>
          <v-row v-if="shouldSchedule">
            <v-col
              cols="6"
            >
              <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    readonly
                    label="Date"
                    hint="DD/MM/YYYY"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  class="dt-picker"
                  :min="minDate"
                  :max="maxDate"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
            >
              <v-menu
                ref="menu"
                v-model="menuTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="timeFormatted"
                    label="Time"
                    hint="HH:MM"
                    persistent-hint
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="menuTime"
                  v-model="time"
                  class="dt-picker"
                  full-width
                  ampm-in-title
                  format="ampm"
                  scrollable
                  :min="minTime"
                  @click:minute="$refs.menu.save(time)"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="!shouldSchedule"
          color="primary"
          text
          @click="send"
        >
          Send
        </v-btn>
        <v-btn
          v-if="shouldSchedule"
          color="primary"
          text
          @click="schedule"
        >
          Schedule
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  // Main JS (in UMD format)
  import moment from 'moment'
  const { gapi } = window

  export default {
    name: 'BulkMessageDialog',
    props: {
      contacts: {
        type: Array,
        default: null,
      },
      templates: {
        type: Array,
        default: null,
      },
      group: {
        type: Object,
        default: null,
      },
      languagesArr: {
        type: Array,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      googleTranslateApiKey: process.env.VUE_APP_GOOGLE_TRANSLATE_API,
      user: null,
      dialog: false,
      groups: [],
      selectedLanguage: 'en',
      field: {
        name: '',
        message: '',
        mediaUrl: '',
        group: '',
      },
      menuDate: false,
      menuTime: false,
      date: moment().format('YYYY-MM-DD'),
      time: moment().add(15, 'minutes').format('HH:mm'),
      maxMessageLength: 200,
      signature: '',
      shouldSchedule: false,
      snackbar: false,
      notification: 'Unknown',
      file: null,
    }),
    computed: {
      minDate () {
        return moment().format('YYYY-MM-DD')
      },
      maxDate () {
        return moment(new Date(new Date().getFullYear(), 11, 31)).add(1, 'years').format('YYYY-MM-DD')
      },
      dateFormatted () {
        return this.date ? moment(this.date).format('D/M/YYYY') : ''
      },
      timeFormatted () {
        return this.time ? moment(this.time, 'HH:mm').format('h:mm A') : ''
      },
      minTime () {
        if (this.date === moment().format('YYYY-MM-DD')) {
          return moment().add(5, 'minutes').format('HH:mm')
        }

        return ''
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
        this.updateGroup()
        if (!val) {
          this.shouldSchedule = false
          this.datetime = ''
        }
      },
    },

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async initialize () {
        gapi.load('client:auth2', this.initClient)
      },

      async makeTranslation (evt) {
        const { body } = await gapi.client.language.translations.list({
          q: this.field.message,
          target: this.selectedLanguage,
        })

        const response = JSON.parse(body)
        this.field.message = response.data.translations[0].translatedText
      },

      replaceBody (template) {
        this.field.message = template.templateBody
      },

      updateGroup () {
        if (this.group && this.group.signiture && this.group.signiture.length) {
          this.maxMessageLength = this.maxMessageLength - this.group.signiture.length
          this.signature = this.group.signiture
        }
      },

      initClient () {
        const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/translate/v2/rest']

        const SCOPES = ['https://www.googleapis.com/auth/cloud-platform']
        const { gapi } = window
        gapi.client.init({
          apiKey: this.googleTranslateApiKey,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        }).then(() => { }, (e) => {})
      },

      close () {
        this.dialog = false
        this.file = null
        this.field = Object.assign({
          name: '',
          message: '',
          mediaUrl: '',
          group: '',
        })
      },

      async schedule () {
        // Check if date and time are valid
        if (!(this.date && this.date.length)) {
          this.notification = 'Please provide a valid date'
          this.snackbar = true
          return
        }
        if (!(this.time && this.time.length)) {
          this.notification = 'Please provide a valid time'
          this.snackbar = true
          return
        }

        const dateTime = moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm', true)

        if (!dateTime.isValid()) {
          this.notification = 'Please select a valid time'
          this.snackbar = true
          return
        }

        if (!dateTime.isAfter()) {
          this.notification = 'Please select a date/time in future'
          this.snackbar = true
          return
        }

        if (!Array.isArray(this.contacts) && !this.contacts.length) {
          return
        }
        if (!this.field.name.length) {
          this.notification = 'Please provide a name'
          this.snackbar = true
          return
        }
        if (!this.field.message.length && !this.file) {
          this.notification = 'Please add a message or select a file to send'
          this.snackbar = true
          return
        }
        if (this.contacts && this.contacts.length) {
          // Send to selected contacts only
          const contacts = []
          this.contacts.forEach((contact) => {
            contacts.push(contact.cellNumber)
          })
          this.$emit('on-send-start')
          let mediaUrl = ''
          if (this.file) {
            mediaUrl = await this.uploadFile()
          }
          const form = {
            ToPhoneNumbers: contacts.join(';'),
            Message: this.field.message,
            GroupID: this.group.id,
            CampaignName: this.field.name,
            Sender: this.usremail,
            MediaURL: mediaUrl,
            StartDate: dateTime.toISOString(),
            MessageTypeID: 1,
          }
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}sms/schedule?code=${this.apiCode}`, form)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        } else {
          // Send to all
          this.$emit('on-send-start')
          let mediaUrl = ''
          if (this.file) {
            mediaUrl = await this.uploadFile()
          }
          const form = {
            ToPhoneNumbers: '',
            Message: this.field.message,
            GroupID: this.group.id,
            CampaignName: this.field.name,
            Sender: this.usremail,
            MediaURL: mediaUrl,
            StartDate: dateTime.toISOString(),
            MessageTypeID: 2,
          }
          this.$emit('on-send-start')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}sms/schedule?code=${this.apiCode}`, form)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        }
      },

      onFileChange (e) {
        const allowedFileTypes = ['image/jpeg',
                                  'image/gif',
                                  'image/png']
        const uploadedFile = e.target.files[0]
        // Check if file type is valid
        if (allowedFileTypes.indexOf(uploadedFile.type) > -1) {
          // Check if it exceeds the size limit
          const allowedFileSize = process.env.VUE_APP_MMS_ATTACHMENT_SIZE_LIMIT_IN_KB || (4 * 1024)
          if ((uploadedFile.size / 1024) <= allowedFileSize) {
            this.file = e.target.files[0]
          } else {
            this.notification = `File size too large, maximum allowed size is ${(allowedFileSize / 1024)} MB`
            this.snackbar = true
          }
        } else {
          this.notification = 'The selected file type is not allowed'
          this.snackbar = true
        }
      },

      onFileDelete () {
        this.file = null
      },

      async uploadFile () {
        try {
          const formData = new FormData()
          formData.append('file', this.file)
          const res = await this.httpConnector.makeRequest('post', `${this.apiEndpoint}mms-media?code=${this.apiCode}`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          return res.data
        } catch (error) {
          return ''
        }
      },

      formatMemory (x) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        let l = 0; let n = parseInt(x, 10) || 0

        while (n >= 1024 && ++l) {
          n = n / 1024
        }
        // include a decimal point and a tenths-place digit if presenting
        // less than ten of KB or greater units
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l])
      },

      async send () {
        if (!Array.isArray(this.contacts) && !this.contacts.length) {
          return
        }
        if (!this.field.name.length) {
          this.notification = 'Please provide a name'
          this.snackbar = true
          return
        }
        if (!this.field.message.length && !this.file) {
          this.notification = 'Please add a message or select a file to send'
          this.snackbar = true
          return
        }
        if (this.contacts && this.contacts.length) {
          // Send to selected contacts only
          const contacts = []
          this.contacts.forEach((contact) => {
            contacts.push(contact.cellNumber)
          })
          this.$emit('on-send-start')
          let mediaUrl = ''
          if (this.file) {
            mediaUrl = await this.uploadFile()
          }
          const form = {
            ToPhoneNumbers: contacts.join(';'),
            Message: this.field.message,
            GroupID: this.group.id,
            CampaignName: this.field.name,
            Sender: this.usremail,
            MediaURL: mediaUrl,
          }
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}TwilioSendBulkSMS?code=${this.apiCode}`, form)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        } else {
          // Send to all
          this.$emit('on-send-start')
          let mediaUrl = ''
          if (this.file) {
            mediaUrl = await this.uploadFile()
          }
          const form = {
            ToPhoneNumbers: 'all',
            Message: this.field.message,
            GroupID: this.group.id,
            CampaignName: this.field.name,
            Sender: this.usremail,
            MediaURL: mediaUrl,
          }
          this.$emit('on-send-start')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}masssms?code=${this.apiCode}`, form)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.placeHoldersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 15px;
  margin-top: 25px;
  margin-bottom: 15px;
  background-color: rgb(212, 149, 0);
  border-radius: 8px;
}
.label {
  color: white;
  font-weight: 600;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 4px 80px 4px 60px;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;
}

.dd-button:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dd-button:hover {
  background-color: #eeeeee;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0 0 0 0;
  z-index: 5555555;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:hover + .dd-menu {
  display: block;
}

.dd-menu li {
  width: 180px;
  padding: 0px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider{
  padding: 0;
  border-bottom: 1px solid #cccccc;
}
.toggleWrapper {
  display: flex;
  align-items: center;
  span {
    font-size: 1.1rem;
    margin-left: 1rem;
  }
input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
input[type=checkbox]:focus {
  outline: 0;
}

.toggle {
  height: 16px;
  width: 42px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  margin: 0;
  border: 2px solid grey;
  background: grey;
  transition: all 0.2s ease;
}
.toggle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
  transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}
.toggle:checked {
  border-color: goldenrod;
  background: goldenrod;
}
.toggle:checked:after {
  transform: translatex(20px);
}
}
</style>
<style lang="scss">
.vdatetime-input {
  border-bottom: 2px solid black;
}
.fileWrapper {
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 25px;
  border: 1px solid goldenrod;
  border-radius: 4px;
  box-shadow: 4px 4px 12px rgba(0,0,0,0.15);
  .filePart {
    flex: 7;
    display: flex;
    align-items: center;
    .iconWrapper {
      margin-right: 12px;
      color: goldenrod;
      * {
        color: goldenrod;
      }
    }
    .nameWrapper {
      margin-top: 3px;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 18px;
      .fileSize {
        font-size: 0.7rem;
        font-weight: 600;
      }
    }
  }
  .actionPart {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .changeBtn {
      background-color: rgb(56, 179, 255);
      padding: 0px 15px;
      cursor: pointer;
      font-weight: 600;
      color: white;
      * {
        font-size: 1.3rem;
        color: white;
        margin-top: -4px;
      }
      border-radius: 50px;
      font-size: 0.8rem;
    }
    .removeBtn {
      cursor: pointer;
      * {
        color: rgb(251, 59, 59);
      }
    }
  }
}
.uploadBtn {
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 25px;
  justify-content: center;
  cursor: pointer;
  border: 1px solid goldenrod;
  border-radius: 4px;
  box-shadow: 4px 4px 12px rgba(0,0,0,0.15);
  .iconWrapper {
      margin-right: 12px;
      color: goldenrod;
      * {
        color: goldenrod;
      }
    }
    .labelWrapper {
      color: goldenrod;
      margin-top: 3px;
      font-size: 0.9rem;
      font-weight: 400;
    }
    &:hover {
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.088);
    }
}

.dt-picker {
  margin: 0;
}

</style>
